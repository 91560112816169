<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Obrok</h5>
                <button type="button" class="btn-close" @click="cancelModal()" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form id="modalAddEditCatering">
                    <div class="mb-3 form-floating">
                        <v-select v-if="employees"
                            :inputId="'inputEmployee'"
                            :options="employees"
                            :reduce="col => col.id"
                            label="full_name" v-model="catering.employee_id"
                            placeholder = "Izaberite zaposlenog"
                            :clearable = "false"
                            class="" id="inputEmployee"
                            >
                            <template slot="option" slot-scope="option">
                                <h5 class="m-0">
                                    <img :src="getAvatarUrl(option)" class="user-select-emp-circle template-avatar" width="50"/>

                                    {{ option.first_name }} {{ option.last_name }}
                                </h5>
                            </template>

                            <template slot="selected-option" slot-scope="option">
                                <h5 class="m-0">
                                    <img :src="getAvatarUrl(option)" class="user-select-emp-circle template-avatar" width="50"/>

                                    {{ option.first_name }} {{ option.last_name }}
                                </h5>
                            </template>

                        </v-select>
                    </div>


                    <div class="mb-3 form-floating">
                        <v-select v-if="allWorkOrders"
                            :inputId="'inputWorkOrder'"
                            :options="allWorkOrders"
                            :reduce="col => col.id"
                            label="name" v-model="catering.work_order_id"
                            placeholder = "Izaberite radni nalog"
                            :clearable = "false"
                            class="" id="inputWorkOrder"
                            >
                            <!-- <template slot="option" slot-scope="option">
                                <h5 class="m-0">
                                    <img :src="getAvatarUrl(option)" class="user-select-emp-circle template-avatar" width="50"/>

                                    {{ option.name }} {{ option.last_name }}
                                </h5>
                            </template>

                            <template slot="selected-option" slot-scope="option">
                                <h5 class="m-0">
                                    <img :src="getAvatarUrl(option)" class="user-select-emp-circle template-avatar" width="50"/>

                                    {{ option.first_name }} {{ option.last_name }}
                                </h5>
                            </template> -->

                        </v-select>

                    </div>


                    <div class="mb-3 d-flex flex-wrap">
                        <div class="form-floating col-7">
                            <input type="date" class="form-control" id="field-catering-date" v-model="catering.date" placeholder="Datum" required>
                            <label for="fiel-liters">Datum</label>
                        </div>
                        <div class="form-floating col-5">
                            <input type="time" class="form-control" id="field-catering-time" v-model="catering.time" placeholder="Vreme sipanja" required>
                            <label for="fiel-liters">Vreme</label>
                        </div>
                        <div class="invalid-feedback">
                            Vreme sipanja je obavezno polje.
                        </div>
                    </div>

                    <div>
                        <label for="fiel-liters">Tip obroka</label>
                        <div role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="catering-order" id="co-ordered" autocomplete="off" v-model="catering.provided_by" value="OWN">
                            <label class="btn btn-xlg btn-outline-primary col-6" for="co-ordered">Samostalno</label>

                            <input type="radio" class="btn-check" name="catering-order" id="co-in-progress" autocomplete="off" v-model="catering.provided_by" value="COMPANY">
                            <label class="btn btn-xlg btn-outline-primary col-6" for="co-in-progress">Restoran</label>

                        </div>
                    </div>

                    <div class="mb-3 form-floating" v-if="catering.provided_by == 'COMPANY'">
                        <select v-model="catering.catering_service_id" class="form-select" aria-label="Restoran" @change="changedCateringService" required>
                            <option disabled :value="undefined">Restoran</option>
                            <option v-for="cateringService in allCateringServices" :key="cateringService.id" :value="cateringService.id">{{ cateringService.name }}</option>
                        </select>
                        <div class="invalid-feedback">
                            Restoran je obavezno polje.
                        </div>
                        <label for="floatingTextarea">Restoran</label>
                    </div>

                    <div class="mb-3 form-floating">
                        <input type="number" min="0" class="form-control" id="field-receipt-id" v-model="catering.price" placeholder="400">
                        <label for="fiel-liters">Cena obroka</label>
                        <div class="invalid-feedback">
                            Cena obroka je obavezno polje.
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="cancelModal()">Zatvori</button>
                <button type="button" class="btn btn-primary" @click="confirmModal()">Sacuvaj</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Modal } from 'bootstrap';
    import {mapState} from 'vuex'
    import UtilService from '@/service/UtilService.js';
    import CateringService from '@/service/CateringService.js';


    export default {

        props: {
            allWorkOrders: {
                type: Array,
                default() {
                    return []
                }
            },

            allCateringServices: {
                type: Array,
                default() {
                    return []
                }
            },
        },

        data() {
            return {
                modal: null,
                catering: {

                },
                message: ""
            }
        },

        computed: {
            ...mapState([
                'allActiveUsers',
                'settings',
            ]),

            employees() {
                let emp = UtilService.deepClone(this.allActiveUsers);
                emp.forEach(element => {
                    element.full_name = element.first_name + " " + element.last_name;
                });
                return emp.sort((a, b) => a?.first_name?.localeCompare(b?.first_name));
            },
        },
        
        methods: {

            confirmModal() {
                if (this.catering.id) this.updateCatering()
                else this.createCatering()
            },

            cancelModal(data) {
                this.hideModal()
                this.$emit('onCancel', data);
            },

         

            showModal(catering) {
                if (catering) this.assignData(catering)
                else this.assignDefaultData()
                this.modal.show();
            },
            hideModal() {
                this.modal.hide();
            },

            setUpListener() {    
                let myModalEl = this.$el
                this.modal = new Modal(myModalEl, {keyboard: false});

                // myModalEl.addEventListener('shown.bs.modal', () => {
                //     this.onModalShown();
                // })
            },

            assignDefaultData() {
                let newCatering = {}
                newCatering.id = null
                newCatering.employee_id = null
                // newCatering.work_order_id = null
                newCatering.date = new Date().toISOString().slice(0,10)
                newCatering.time = new Date().toISOString().slice(11,16)
                if (this.catering.price == null && this.catering.provided_by != "COMPANY") newCatering.price = this.settings.find(a => a.identifier == "catering__self_catering_price").value
                if (this.catering.provided_by != "COMPANY") newCatering.provided_by = "OWN"
                if (this.catering.provided_by == "OWN") newCatering.catering_service_id = null

                this.catering = {...this.catering, ...newCatering} 
            },

            assignData(catering) {
                let newCatering = {}
                newCatering.id = catering.id
                newCatering.employee_id = catering.employee_id
                newCatering.work_order_id = catering.work_order_id
                newCatering.date = catering.date
                newCatering.time = catering.time
                newCatering.price = catering.price
                newCatering.provided_by = catering.provided_by
                newCatering.catering_service_id = catering.catering_service_id

                this.catering = {...this.catering, ...newCatering}
            },

            async createCatering() {
                let form = document.getElementById("modalAddEditCatering");
                form.classList.add('was-validated');
                if (!form.checkValidity())
                    return;

                await CateringService.addCatering(this.catering)
                    .then(() => {

                        this.toast("Dodavanje obroka uspešno.");
                        this.$emit('onConfirm');
                        this.hideModal()
                    })
                    .catch((error) => alert(error.message));    
            },

            async updateCatering() {
                let form = document.getElementById("modalAddEditCatering");
                form.classList.add('was-validated');
                if (!form.checkValidity())
                    return;

                await CateringService.putCatering(this.catering)
                    .then(() => {

                        this.toast("Menjanje obroka uspešno.");
                        this.$emit('onConfirm');
                        this.hideModal()
                    })
                    .catch((error) => alert(error.message));    

            },


            loadAllWorkers() {
                this.$store.dispatch('loadAllActiveUsers')
            },
            getAvatarUrl(employee) {
                return employee.avatar_url?employee.avatar_url:require('@/assets/profilna.png')
            },

            changedCateringService() {
                console.log(this.catering.catering_service_id);
                let cateringService = this.allCateringServices.find(cateringService => cateringService.id == this.catering.catering_service_id)
                this.catering.price = cateringService.price
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            }

        },

        created() {
            this.loadAllWorkers()
        },

        mounted() {
            this.setUpListener();
        },

    }
</script>

<style scoped>
    .v-select.form-control{
        height: 4.5em;
    }

    .user-select-emp-circle {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
    }

    #modalAddEditCatering .v-select .vs__search {
        min-height: 60px;
    }

</style>

<style>
    #modalAddEditCatering .v-select .vs__search {
        min-height: 60px;
    }
</style>