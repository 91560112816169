<template>
    <div>
        <h4>Porudzbina</h4>
        <template v-if="true || isBeforeOrder">
            <p v-if="countdown === null">Porudzbina poslata</p>
            <p v-else>Porudzbina se predaje u {{ placeSmsOrderAt }} <small> - ({{ countdown }})</small></p>
            <div v-if="plannedCateringOrders">
                <div class="mb-3" v-for="plannedCateringOrder in plannedCateringOrders" :key="plannedCateringOrder.id">
                    <p class="mb-0">Lokacija: <strong>{{ plannedCateringOrder.work_order_name }}</strong></p>
                    <p class="mb-0">Restoran: <strong>{{ plannedCateringOrder.catering_service.name }}</strong></p>
                    <p class="mb-0">Broj obroka: <strong>{{ plannedCateringOrder.meal_count }}</strong></p>
                    <p class="mb-0">Poručilac (poslovođa): <strong>{{ plannedCateringOrder.purchaser.first_name }} {{ plannedCateringOrder.purchaser.last_name }} ({{ plannedCateringOrder.phone_number }})</strong></p>
                    <p class="mb-0">Cena: <strong>{{ plannedCateringOrder.unit_price }} * {{ plannedCateringOrder.meal_count }} = {{ plannedCateringOrder.unit_price * plannedCateringOrder.meal_count }}</strong></p>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
    import moment from 'moment';
    import UtilService from '@/service/UtilService.js';
    import CateringService from '@/service/CateringService.js';
    import {mapState} from 'vuex'

    export default {

        props: {
            selectedDate: {
                type: String,
                default: UtilService.formatDatePattern(UtilService.getMoment())
            },
            workOrderId: {
                type: Number,
            }
        },

        data() {
            return {
                plannedCateringOrders: null,
                countdown: null,
                timer: null
            }
        },

        computed: {
            ...mapState([
                'settings',
            ]),

            isBeforeOrder() {
                if (!this.settings) return false

                let current_time = (new Date()).toLocaleTimeString('en-US', { hour12: false }); 
                if (this.placeSmsOrderAt >= current_time) return true
                return false
            },

            placeSmsOrderAt() {
                if (!this.settings) return ""

                let assign_end_time = this.settings.find(a => a.identifier == "catering__place_sms_order_at").value
                return assign_end_time
            },

        },

        watch: {
            workOrderId: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.fetchData();
                    }
                },
            },
            selectedDate: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.fetchData();
                    }
                },
            },
        },

        methods: {
            loadPlannedCateringOrder() {
                let payload = {
                    params: {
                        "date": this.selectedDate,
                        "work_order_id": this.workOrderId
                    }
                }
                console.log(payload);

                CateringService.getPlannedCateringOrder(payload).then(async (result)=>{
                    this.plannedCateringOrders = result.data;
                });
            },
            
            
            updateCountdown() {
                let targetTime = moment(this.placeSmsOrderAt, "HH:mm:ss")
                const now = moment();
                const duration = moment.duration(targetTime.diff(now));
                // Format the countdown string

                if (duration.milliseconds() < 0) {
                    this.countdown = null
                    clearInterval(this.timer)
                    return
                } 
                this.countdown = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
            },

            fetchData() {
                this.loadPlannedCateringOrder();
            }

            
        },

        created() {
            if (!this.settings) this.$store.dispatch('loadUserSettings');
            if (this.isBeforeOrder) this.timer = setInterval(this.updateCountdown, 1000);
            this.fetchData();

        }

    }
</script>

<style>

</style>