<template>
    <div>
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container mb-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#today-catering-tab" type="button" role="tab" aria-controls="today-catering-tab" aria-selected="true">Danasnji obroci</button>
                </li>
                <li v-if="isAdmin" class="nav-item" role="presentation">
                    <button class="nav-link" id="restaurants-link" data-bs-toggle="tab" data-bs-target="#restaurants-tab" type="button" role="tab" aria-controls="restaurants-tab" aria-selected="true" >Restorani</button>
                </li>
                <li v-if="isAdmin" class="nav-item" role="presentation">
                    <button class="nav-link" id="restaurants-stats-link" data-bs-toggle="tab" data-bs-target="#restaurants-stats-tab" type="button" role="tab" aria-controls="restaurants-stats-tab" aria-selected="true" >Statistika</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="catering-link" data-bs-toggle="tab" data-bs-target="#all-catering-tab" type="button" role="tab" aria-controls="all-catering-tab" aria-selected="false">Svi obroci</button>
                </li>
            </ul>

            <div class="tab-content mt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="today-catering-tab" role="tabpanel" aria-labelledby="home-tab">
                    <h2 class="">Obroci</h2>
                    <today-caterings-table />
                </div>
                <div v-if="user.role === 'ADMIN'" class="tab-pane fade" id="restaurants-tab" role="tabpanel" aria-labelledby="restaurants-tab">
                    <modal-edit-w-o-restaurant modalId="woRestaurantsModal" title="Dodela restorana" :thisModal="woRestaurantsModal" />
                    <h2>Restorani</h2>
                    <button class="btn btn-primary btn-lg" @click="openRestaurantsModal">Podesi restorane</button>
                    <catering-services-table />
                </div>
                <div v-if="user.role === 'ADMIN'" class="tab-pane fade" id="restaurants-stats-tab" role="tabpanel" aria-labelledby="restaurants-stats-tab">
                    <catering-statistic-table />
                </div>
                <div class="tab-pane fade" id="all-catering-tab" role="tabpanel" aria-labelledby="profile-tab">
                    <h2 class="">Svi obroci</h2>
                    <caterings-table />
                </div>
                
                
            </div>

        </div>

    </div>
    
</template>

<script>

    import Navbar from '@/components/Navbar.vue'
    import {mapState, mapGetters} from 'vuex'
    import CateringServicesTable from '@/components/catering/CateringServicesTable.vue'
    import CateringStatisticTable from '@/components/catering/CateringStatisticTable.vue'
    import TodayCateringsTable from '@/components/catering/TodayCateringsTable.vue'
    import CateringsTable from '@/components/catering/CateringsTable.vue'
    import { Modal } from 'bootstrap';
    import ModalEditWORestaurant from '@/components/modals/catering/ModalEditWORestaurant.vue'


    export default {
        components: {
            Navbar,
            CateringServicesTable,
            TodayCateringsTable,
            CateringsTable,
            ModalEditWORestaurant,
            CateringStatisticTable
        },

        data() {
            return {
                woRestaurantsModal: null,

                transfer_data: [],
            }
        },

        computed: {
            ...mapState([
                'user',
            ]),

            ...mapGetters([
                'isAdmin',
                'isManager',
            ]),

            homePage() {
                return (this.$route.path === "/alat");
            }
        },

        methods: {
            openRestaurantsModal() {
                this.woRestaurantsModal.show()
            }

        },

        mounted() {
            this.woRestaurantsModal = new Modal(document.getElementById('woRestaurantsModal'));

        },

        created() {
        },


    }
</script>

<style scoped>
    .button-tabs {
        margin-bottom: 2em;
    }

    .button-tabs > .btn-tab {
        padding: 1em 2em;
        position: relative;
        display: inline-block;
    }

    .button-tabs .router-link-active {
        background: #0d6efd !important;
        color: #fff !important;
    }

    .request-badge {
        position: absolute;
        top: 0px;
        right: -10px;
        font-size: 1em;
        padding: 7px 10px;
        border-radius: 100%;
        background: red;
        color: white;
    }

</style>