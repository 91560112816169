<template>
    <div>
        <modal-add-edit-catering ref="modalAddEditCatering" :allWorkOrders="allActiveWorkOrders" :allCateringServices="cateringServices" @onConfirm="updatedCatering" />
        <modal-confirm ref="modalConfirm" :title="confirmModalTitle" :message="confirmModalMessage" @close="confirmModalOnClose" @confirm="confirmModalOnConfirm" />

        <div class="form-floating col-md-4">
            <input type="date" class="form-control" id="attendenceDate" v-model="selected_date" placeholder="Za datum" required @change="selectedDateChanged">
            <label for="attendenceDate">Za datum</label>
            <div class="invalid-feedback">
                Unesite datum
            </div>
        </div>
        <div class="form-floating col-md-4">
            <select type="date" class="form-control" id="woDeleted" v-model="selected_wo" placeholder="Radni nalog" @change="woChanged">
                    <option disabled :value="null">Izaberite radni nalog</option>
                    <template v-if="isAdmin">
                        <option :value="-1" :key="-1">SVI Nalozi</option>
                        <option v-for="wo in allActiveWorkOrders" :value="wo" :key="wo.id">{{wo.name}}</option>
                    </template>
                    <template v-else-if="userAssignment">
                        <option v-for="pwo in preWorkOrdersFilterWorking" :value="pwo.work_order_obj" :key="pwo.work_order_obj.id">{{pwo.work_order_obj.name}}</option>
                    </template>
            </select>
            <label for="woAgreedValue">Radni nalog</label>
        </div>

        <div class="col-lg-12 control-section mb-3">
            <div>
                <ejs-grid
                    ref="caterings"
                    :dataSource="caterings" 
                    class="all-caterings"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :allowPaging='true' 
                    :pageSettings='pageSettings'
                    :allowResizing='true'
                    :allowReordering='true'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    :toolbar='toolbar'
                    :toolbarClick='toolbarClick'
                    :recordDoubleClick="cateringRowDoubleClick"

                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" width='60' textAlign='Right' :visible='true' ></e-column>

                        <e-column field='id' headerText='ID' width='120' textAlign='Left' :isPrimaryKey='true' :visible='false' ></e-column>
                        <e-column field='employee.first_name' headerText='Radnik' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                        <e-column field='date' :valueAccessor='dateAccessor' headerText='Datum' width='120' textAlign='Left' :visible="false"></e-column>
                        <e-column field='time' headerText='Vreme' width='120' textAlign='Left' :visible="false"></e-column>
                        <e-column field='work_order_name' headerText='Radni Nalog' width='120' textAlign='Left' ></e-column>
                        <e-column field='catering_service_name' headerText='Restoran' width='120' textAlign='Left' ></e-column>
                        <e-column field='price' headerText='Cena obroka' width='120' textAlign='Left' ></e-column>
                        <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Vreme dodavanja' width='150' textAlign="Left" :visible="true"></e-column>
                        <e-column field='created_by' headerText='Napravio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                        <e-column field='modified_by' headerText='Izmenio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                        <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Vreme izmene' width='130' textAlign="Left" :visible="false"></e-column>
                    </e-columns>

                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column type="Count" field="employee.first_name"></e-column>
                                <e-column type="Sum" field="price"></e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </div>
        </div>

        <div v-if="selected_wo">
            <catering-order-view ref="cateringOrderView" :selectedDate="selected_date" :workOrderId="selected_wo.id" />
        </div>
    </div>
</template>

<script>
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate } from "@syncfusion/ej2-vue-grids";
    // import ModalAddEditCateringService from "@/components/modals/catering/ModalAddEditCateringService.vue";
    // import { Modal } from 'bootstrap'
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import CateringService from '@/service/CateringService.js';
    import UtilService from '@/service/UtilService.js';
    import EmployeesService from '@/service/EmployeesService.js';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';
    import {mapState, mapGetters} from 'vuex'
    import moment from 'moment';
    import ModalAddEditCatering from '../modals/catering/ModalAddEditCatering.vue';
    import ModalConfirm from '../modals/ModalConfirm.vue';
import CateringOrderView from './CateringOrderView.vue';


    export default {
        components: { ModalAddEditCatering, ModalConfirm, CateringOrderView },
        // components: { ModalAddEditCateringService },
        data() {
            return {
                // modalAddEditCateringService: null,
                countdown: "",

                editCatering: undefined,
                selected_wo: null,
                preWorkOrders: [],

                filterSettings: { type: "Excel" },
                toolbar: [
                        { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addCatering' }, 
                        { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editCatering', disabled: false}, 
                        { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteCatering', disabled: false},                        
                        ],
                

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                },

                caterings: [],
                plannedCateringOrder: null,
                selected_date: UtilService.formatDatePattern(UtilService.getMoment()),
                dateFormat: "dd.MM.yyyy.",

                pageSettings: { pageCount: 5, pageSize: 20},

            
                usernameTemplate: function() {
                    return {
                        template: {
                            extends: UsernameTemplate,
                            propsData: {
                                showAvatar: true,
                                showName: true,
                                showLastName: true,
                                showUsername: false,
                                profilePage: 'EmployeeCarnet',
                                dataLocation: 'employee',
                            role: true,
                                
                            },
                        }

                    };
                },

                cateringServices: [],
                confirmModalTitle: "",
                confirmModalMessage: "",
                confirmModalOnClose: "",
                confirmModalOnConfirm: "",

            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate ]
        },

        computed: {
            ...mapState([
                'user',
                'allWorkOrders',
                'userAssignment',
                'settings',
            ]),

            ...mapGetters([
                'isAdmin',
                'isManager',
            ]),

            allActiveWorkOrders() {
                return this.allWorkOrders.filter(x => !x.real_end_date || x.real_end_date > this.todayDate)
            },

            preWorkOrdersFilterWorking() {
                if (this.pwo_role == 1 || this.user.role == 'WORKER') return [this.userAssignment?.pwo]
                return this.preWorkOrders.filter(x => x.id && x.work_order?.name != "").filter(x => !x.real_end_date || x.real_end_date > this.todayDate);
            },

            placeSmsOrderAt() {
                if (!this.settings) return ""

                let assign_end_time = this.settings.find(a => a.identifier == "catering__place_sms_order_at").value
                return assign_end_time
            },

            isBeforeOrder() {
                if (!this.settings) return false

                let current_time = (new Date()).toLocaleTimeString('en-US', { hour12: false }); 
                if (this.placeSmsOrderAt >= current_time) return true
                return false
            },

            meals_quantity() {
                let count = 0
                
                this.caterings.forEach(catering => {
                    if (catering.provided_by == "COMPANY") count += 1
                })

                return count
            }
        },

        methods: {
            formatDateTime : UtilService.formatDateTime,
            formatDate : UtilService.formatDate,

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },

            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },

            cateringRowDoubleClick(event) {
                this.openUpdateModal(event.rowData)
            },


            toolbarClick(args) {
                console.log(args);
                if (args.item.id === 'addCatering') {
                    this.openAddCateringModal()
                }

                else if (args.item.id === "editCatering"){
                    let selected = (this.$refs.caterings.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openUpdateModal(selected)
                }
                else if (args.item.id === "deleteCatering"){
                    let selected = (this.$refs.caterings.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openDeleteCateringConfirmation(selected)
                }

            },


            openAddCateringModal() {
                this.$refs.modalAddEditCatering.showModal()

            },

            openUpdateModal(catering) {
                this.$refs.modalAddEditCatering.showModal(catering)
            },

            openDeleteCateringConfirmation(catering) {
                let removeCatering = this.removeCatering
                this.confirmModalTitle = "Potvrda brisanja"
                this.confirmModalMessage = "Da li ste sigurni da želite da obrišete obrok"
                this.confirmModalOnClose = function() {}
                this.confirmModalOnConfirm = function() {removeCatering(catering.id)}
                this.$refs.modalConfirm.showModal()
            },

            rowDataBound() {
                return
            },

            confirmedCateringService() {
                this.loadCateringServices();
            },

            rangeChanged() {
                this.loadCaterings();
            },

            loadCaterings() {
                let query = {
                    "date": this.selected_date,
                    "work_order": this.selected_wo.id
                }

                CateringService.getAllCaterings(query).then(async (result)=>{
                    this.caterings = result.data;
                    this.caterings.forEach(catering => {
                        catering["employee"] = {user: catering["employee"]}
                    });
                });
            },

            loadPlannedCateringOrder() {
                let query = {
                    "date": this.selected_date,
                    "work_order_id": this.selected_wo.id
                }
                console.log(query);

                CateringService.getPlannedCateringOrder(query).then(async (result)=>{
                    this.plannedCateringOrder = result.data;
                });
            },

            async removeCatering(cateringId) {
                await CateringService.deleteCatering(cateringId)
                    .then(() => {
                        UtilService.toast(this, "Brisanje obroka uspešno.");
                        this.updatedCatering();
                    })
                    .catch((error) => alert(error.message));    

            },

            async loadPreWorkOrders() {
                try {
                    let result = await EmployeesService.getPreWorkOrdersForEmployee({id: this.user.id, date: this.selected_date});
                    this.preWorkOrders = result.data;

                    // QUIICK FIX
                    this.preWorkOrders.forEach(element => {
                        element.work_order_obj = element.work_order;
                    });
                    
                } catch (error) {
                    console.log(error);
                }

            },

            updateCaterings() {
                this.loadCaterings();
                this.$refs.cateringOrderView.fetchData();

            },

            woChanged() {
                this.updateCaterings();

            },

            selectedDateChanged() {
                this.updateCaterings();
            },

            async loadAllWorkOrders() {
                await this.$store.dispatch('loadAllWorkOrders', this.workOrderForm).catch((error) => {
                    alert("neuspesno ucitavanje radnih naloga");
                    console.log(error);
                });

            },
            
            updateCountdown() {
                let targetTime = moment(this.placeSmsOrderAt, "HH:mm:ss")
                const now = moment();
                const duration = moment.duration(targetTime.diff(now));
                // Format the countdown string

                if (duration.milliseconds() < 0) {
                    this.countdown = null
                    clearInterval(this.timer)
                    return
                } 
                this.countdown = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
            },

            loadCateringServices() {
                CateringService.getAllCateringServices().then(async (result)=>{
                    this.cateringServices = result.data;
                });
            },

            updatedCatering() {
                this.updateCaterings();
            },


        },

        mounted() {
            // this.modalAddEditCateringService = new Modal(document.getElementById('modalAddEditCateringService'));
        },

        async created() {
            if (this.isBeforeOrder) this.timer = setInterval(this.updateCountdown, 1000);

            if (this.isAdmin) {
                this.loadAllWorkOrders();
                this.selected_wo = -1

            } else {
                await this.loadPreWorkOrders()
                this.selected_wo = this.preWorkOrdersFilterWorking[0].work_order_obj

            }
            this.loadCateringServices();
            this.updateCaterings();

        }

    }
</script>

<style>

</style>