<template>
    <div>
        <modal-add-edit-catering-service :thisModal="modalAddEditCateringService" modalId="modalAddEditCateringService" title="Restoran" :editCateringService="editCateringService" @confirmed="confirmedCateringService" />
        
        <div class="col-lg-12 control-section">
            <div>
                <ejs-grid
                    ref="cateringServices"
                    :dataSource="cateringServices" 
                    class="all-catering-services"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :recordDoubleClick="employeesDBClick"
                    :rowSelected="employeeSelect"
                    :rowDeselected="employeeDeselect"
                    :toolbar='toolbar'
                    :toolbarClick='clickHandler'
                    :rowDataBound='rowDataBound'
                    :allowResizing='true'
                    :allowReordering='true'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" width='60' textAlign='Right' :visible='true' ></e-column>

                        <e-column field='id' headerText='ID' width='120' textAlign='Left' :isPrimaryKey='true' :visible='false' ></e-column>
                        <e-column field='name' headerText='Naziv restorana' width='120' textAlign='Left' ></e-column>
                        <e-column field='location' headerText='Lokacija' width='120' textAlign='Left' ></e-column>
                        
                        <e-column field='phone' headerText='Broj telefona' width='150'></e-column>
                        <e-column field='price' headerText='Cena obroka' width='150'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import ModalAddEditCateringService from "@/components/modals/catering/ModalAddEditCateringService.vue";
    import { Modal } from 'bootstrap'
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import CateringService from '@/service/CateringService.js';

    export default {
        components: { ModalAddEditCateringService },
        data() {
            return {
                modalAddEditCateringService: null,
                editCateringService: undefined,

                filterSettings: { type: "Excel" },
                toolbar: [
                        { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addCateringService' }, 
                        { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editCateringService', disabled: false}, 
                        { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteEmployee', disabled: false},
                        { text: 'Povrati', tooltipText: 'Povrati', prefixIcon: 'e-delete', id: 'restoreEmployee', visible: false},
                        
                        { text: 'Prikaži obrisane', tooltipText: 'Prikaži neaktivne', prefixIcon: 'e-insert-column-right', id: 'showDeleted', align: 'Right'},
                        { text: 'Sakrij obrisane', tooltipText: 'Sakrij neaktivne', prefixIcon: 'e-delete-column-right', id: 'hideDeleted', align: 'Right', visible: false}
                        ],
                

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                },

                cateringServices: [],

            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport  ]
        },

        methods: {

            employeesDBClick(event) {
                console.log(event);
            },

            async employeeSelect(event) {
                console.log(event);
            },

            async employeeDeselect(event) {
                console.log(event);
            },

            clickHandler(args) {
                console.log(args);
                if (args.item.id === 'addCateringService') {
                    this.showAddCateringService()
                }

                else if (args.item.id === "editCateringService"){
                    let selected = (this.$refs.cateringServices.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openUpdateModal(selected)
                }

            },


            showAddCateringService() {
                this.editCateringService = undefined
                this.modalAddEditCateringService.show();   
            },

            openUpdateModal(cateringService) {
                console.log(cateringService);
                this.editCateringService = JSON.parse(JSON.stringify(cateringService));                
                this.modalAddEditCateringService.show();   
            },

            rowDataBound() {
                return
            },

            confirmedCateringService() {
                this.loadCateringServices();
            },

            loadCateringServices() {
                CateringService.getAllCateringServices().then(async (result)=>{
                    this.cateringServices = result.data;
                });
            }
        },

        mounted() {
            this.modalAddEditCateringService = new Modal(document.getElementById('modalAddEditCateringService'));
        },

        created() {
            this.loadCateringServices();
        }

    }
</script>

<style>

</style>