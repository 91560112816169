<template>
    <div>
        <!-- <modal-add-edit-catering-service :thisModal="modalAddEditCateringService" modalId="modalAddEditCateringService" title="Restoran" :editCateringService="editCateringService" @confirmed="confirmedCateringService" /> -->
        
        <div class="col-md-4">
            <ejs-daterangepicker 
                placeholder="Period"
                v-model="dateRange"
                :format="dateFormat"
                :openOnFocus='true'
                :change='rangeChanged'
                :firstDayOfWeek=1
                :serverTimezoneOffset=7.0
                />
        </div>

        <div class="col-lg-12 control-section">
            <div>
                <ejs-grid
                    ref="caterings"
                    :dataSource="caterings" 
                    class="all-caterings"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :allowPaging='true' 
                    :pageSettings='pageSettings'
                    :allowResizing='true'
                    :allowReordering='true'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    
                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" width='60' textAlign='Right' :visible='true' ></e-column>

                        <e-column field='id' headerText='ID' width='120' textAlign='Left' :isPrimaryKey='true' :visible='false' ></e-column>
                        <e-column field='user.first_name' headerText='Radnik' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                        <e-column field='date' :valueAccessor='dateAccessor' headerText='Datum' width='120' textAlign='Left' ></e-column>
                        <e-column field='time' headerText='Vreme' width='120' textAlign='Left' ></e-column>
                        <e-column field='work_order' headerText='Radni Nalog' width='120' textAlign='Left' ></e-column>
                        <e-column field='catering_service_name' headerText='Restoran' width='120' textAlign='Left' ></e-column>
                        <e-column field='price' headerText='Cena obroka' width='120' textAlign='Left' ></e-column>
                        <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Vreme dodavanja' width='150' textAlign="Left" :visible="true"></e-column>
                        <e-column field='created_by' headerText='Napravio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                        <e-column field='modified_by' headerText='Izmenio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                        <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Vreme izmene' width='130' textAlign="Left" :visible="false"></e-column>
                    </e-columns>

                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column type="Count" field="user.first_name"></e-column>
                                <e-column type="Sum" field="price"></e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate } from "@syncfusion/ej2-vue-grids";
    // import ModalAddEditCateringService from "@/components/modals/catering/ModalAddEditCateringService.vue";
    // import { Modal } from 'bootstrap'
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import CateringService from '@/service/CateringService.js';
    import UtilService from '@/service/UtilService.js';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';

    export default {
        // components: { ModalAddEditCateringService },
        data() {
            return {
                // modalAddEditCateringService: null,
                editCatering: undefined,

                filterSettings: { type: "Excel" },
                toolbar: [
                        { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addCateringService' }, 
                        { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editCateringService', disabled: false}, 
                        { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteEmployee', disabled: false},
                        { text: 'Povrati', tooltipText: 'Povrati', prefixIcon: 'e-delete', id: 'restoreEmployee', visible: false},
                        
                        { text: 'Prikaži obrisane', tooltipText: 'Prikaži neaktivne', prefixIcon: 'e-insert-column-right', id: 'showDeleted', align: 'Right'},
                        { text: 'Sakrij obrisane', tooltipText: 'Sakrij neaktivne', prefixIcon: 'e-delete-column-right', id: 'hideDeleted', align: 'Right', visible: false}
                        ],
                

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                },

                caterings: [],
                dateRange: [
                    UtilService.getMoment().toDate(),
                    UtilService.getMoment().toDate()
                ],
                dateFormat: "dd.MM.yyyy.",

                pageSettings: { pageCount: 5, pageSize: 20},

            
                usernameTemplate: function() {
                    return {
                        template: {
                            extends: UsernameTemplate,
                            propsData: {
                                showAvatar: true,
                                showName: true,
                                showLastName: true,
                                showUsername: false,
                                profilePage: 'EmployeeProfile',
                                dataLocation: 'employee',
                                role: true,
                                
                            },
                        }

                    };
                },

            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate  ]
        },

        methods: {
            formatDateTime : UtilService.formatDateTime,
            formatDate : UtilService.formatDate,

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },

            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },

            employeesDBClick(event) {
                console.log(event);
            },


            clickHandler(args) {
                console.log(args);
                if (args.item.id === 'addCateringService') {
                    this.showAddCateringService()
                }

                else if (args.item.id === "editCateringService"){
                    let selected = (this.$refs.cateringServices.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openUpdateModal(selected)
                }

            },


            // showAddCateringService() {
            //     this.editCateringService = undefined
            //     this.modalAddEditCateringService.show();   
            // },

            // openUpdateModal(cateringService) {
            //     console.log(cateringService);
            //     this.editCateringService = JSON.parse(JSON.stringify(cateringService));                
            //     this.modalAddEditCateringService.show();   
            // },

            rowDataBound() {
                return
            },

            confirmedCateringService() {
                this.loadCateringServices();
            },

            rangeChanged() {
                this.loadCaterings();
            },

            loadCaterings() {
                let query = {
                    "start-date": UtilService.dateToUTC(this.dateRange[0]).toISOString().slice(0,10),
                    "end-date": UtilService.dateToUTC(this.dateRange[1]).toISOString().slice(0,10)
                }

                CateringService.getAllCaterings(query).then(async (result)=>{
                    this.caterings = result.data;
                    this.caterings.forEach(catering => {
                        catering["employee"] = {user: catering["employee"]}
                    });
                });
            }
        },

        mounted() {
            // this.modalAddEditCateringService = new Modal(document.getElementById('modalAddEditCateringService'));
        },

        created() {
            this.loadCaterings();
        }

    }
</script>

<style>

</style>