<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div>
                        <form @submit.prevent="" id="catering-service-form">
                            <div class="container col-md-10 d-grid gap-2">
                                <div class="form-floating">
                                    <input list="inputNames" v-model="cateringService.name" class="form-control" type="text" placeholder="Restoran" aria-label="Naziv restorana" required>
                                    <label for="floatingInput">Naziv restorana:</label>
                                    <div class="invalid-feedback">
                                        Naziv je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">
                                    <input list="inputNames" v-model="cateringService.location" class="form-control" type="text" placeholder="Adresa" aria-label="Adresa restorana">
                                    <label for="floatingInput">Adresa restorana:</label>
                                    <div class="invalid-feedback">
                                        Adresa je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">
                                    <input list="inputNames" v-model="cateringService.phone" class="form-control" type="text" placeholder="Broj telefona" aria-label="Broj telefona">
                                    <label for="floatingInput">Broj telefona:</label>
                                    <div class="invalid-feedback">
                                        Broj telefona je obavezno polje.
                                    </div>
                                </div>


                                <div class="form-floating">
                                    <input v-model="cateringService.price" class="form-control" type="number" min="0" max="100000" aria-label="Cena obroka">
                                    <label for="floatingInput">Cena obroka:</label>
                                    <div class="invalid-feedback">
                                        Cena je obavezno polje.
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button :disabled="uploading" @click="submitCatering()" type="button" class="btn btn-primary">
                        Potvrdi
                        <div v-if="uploading" class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import CateringService from '@/service/CateringService.js';


    export default {

        props: {
            modalId: String,
            title: String,
            editCateringService: Object,
            thisModal: Object
        },

        data() {
            return {
                cateringService: {
                    id: undefined,
                    name: "",
                    location: "",
                    phone: "",
                    price: 0,
                },

                uploading: false,
                file: [],

                reader: false,

            }
        },

        computed: {
            ...mapState([
            ]),


        },


        methods: {

            validate() {
                let form = document.getElementById("catering-service-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitCatering() {
                if (!this.validate()) return

                if (this.editCateringService === undefined) this.addCateringService();
                else this.putCateringService();


            },

            putCateringService() {
                CateringService.putCateringService(this.cateringService).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            addCateringService() {
                CateringService.addCateringService(this.cateringService).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');

                })
            },

            initData() {
                console.log("init");
                if (this.editCateringService !== undefined) {
                    console.log("INIT DEO");
                    this.cateringService = {
                        id: this.editCateringService.id,
                        name: this.editCateringService.name,
                        location: this.editCateringService.location,
                        phone: this.editCateringService.phone,
                        price: this.editCateringService.price,
                    }
                } else {
                    console.log("else");
                    this.cateringService = {
                        id: undefined,
                        name: "",
                        location: "",
                        phone: "",
                        price: undefined,
                    }
                }

                console.log(this.cateringService);
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

        },

        mounted() {
            this.setUpListener();
        },

        created() {
            this.initData();
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.flex-shrink-3 {
    flex-shrink: 2;
}

.gallery-image {
    position: relative;

}

.gallery-image .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
}

.gallery-image:hover .overlay {
    opacity: 0.3;
}

.gallery-image .img-btn-group .btn {
    color: white;
}


.gallery-image .img-btn-group {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    transition: .5s ease;
    opacity: 0;
}

.gallery-image:hover .img-btn-group {
    opacity: 1;
}



.main-image {
    display: inline-block;

}
</style>